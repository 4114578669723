<template>
    <div class="top_head"></div>
		<div class="Info_wrp">
			<div class="top_img">
				<img class="succPic" src="../assets/sucs.png"  />
			</div>
			<div class="tit">
				支付成功
			</div>
		</div>
</template>
<style>
	body {
        background: none;
		background-color: #fff;
	}

	.adWrp {
		border-top: 1px solid #eee;
		margin: 0 20px;
		margin-top: 30px;
		padding: 20px 0;
	}

	.money {
		text-align: center;
		font-size: 12px;
	}

	.money text {
		font-size: 26px;
		font-weight: bold;
	}

	.top_img {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.succPic {
		width: 30%;
	}

	.adPic {
		width: 100%;
	}

	.ewmPic {
		width: 30%;
	}

	.tit {
		font-size: 14px;
		color: #333;
		margin: 10px auto;
		font-weight: bold;
		text-align: center;
	}

	.btns {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.btns {
		margin: 0 20px
	}

	.btns navigator {
		text-align: center;
		line-height: 40px;
		display: block;
		width: 100%;
		color: #005bac;
		background-color: #fff;
		border: 1px solid #005bac;
		font-size: 15px;
		border-radius: 4px;
	}

	.payBtn {
		margin: 22px auto;
		text-align: center;
		line-height: 40px;
		width: 43%;
		color: #fff;
		background-color: #005bac;
		font-size: 15px;
		border-radius: 24px;
	}

	.payBtn {
		width: 93%;
		line-height: 47px;
		background-color: #DD524D;
	}

	.succ_info {
		width: 80%;
		margin: 20px auto;
		font-size: 15px;
		line-height: 1.8;
	}

	.Info_wrp {
        padding-top: 12vh;
		background-color: #fff;
		padding-bottom: 6px;
	}

</style>